import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  datoApi,
  defaultSettings,
  getApiSettingsFromHost,
} from '@hultafors/solidgear/api';
import {
  GlobalFields,
  NotFoundPageQuery,
  PageProps,
} from '@hultafors/solidgear/types';

const Products = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.Products),
);
const Section = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.Section),
);
const Grid = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.Grid),
);
const GridChild = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.GridChild),
);
const ContentHero = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.ContentHero),
);
const Loader = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.Loader),
);

export interface PageNotFoundProps extends PageProps {
  content: NotFoundPageQuery['notFoundPage'];
}
export const getStaticProps: GetStaticProps<PageNotFoundProps> = async () => {
  try {
    const settings = getApiSettingsFromHost(defaultSettings.hostname);
    const dato = datoApi(settings);

    const {
      data: { notFoundPage: content, ...globalFields },
    } = await dato.getNotFoundPage();

    const props: PageNotFoundProps = {
      ...(globalFields as GlobalFields),

      content,
      settings,
    };
    return { props };
  } catch (error) {
    console.error(error);
    return {
      props: {} as PageNotFoundProps,
    };
  }
};

const Custom404: NextPage<PageNotFoundProps> = ({
  // content: defaultContent,
  // settings: defaultSettings,
  content,
}) => {
  // const [, setSettings] = useState<ApiSettings>(defaultSettings);
  // const [content, setContent]
  //   = useState<NotFoundPageQuery['notFoundPage']>(undefined);
  // const { setGlobal, global, setAllMenuTrees, setFooter } = useGlobal();
  // const { data: pageData, error: pageError } = useSWR<NotFoundPageQuery>(
  //   '/api/pageNotFound',
  //   {
  //     revalidateOnMount: true,
  //   },
  // );
  // const {
  //   data: productsData,
  //   // error: productsError,
  //   isValidating: productsLoading,
  // } = useSWR<ParsedProductsApiResponse<SolidgearProduct>>(
  //   defaultContent?.products
  //   && `/api/productslist/${23}?productCatalogNodeId=${
  //       defaultContent.products
  //     }&pageSize=${PAGE_SIZE_S}`,
  //   {
  //     revalidateOnMount: true,
  //   },
  // );

  // useEffect(() => {
  //   setSettings(getApiSettingsFromHost(window.location.hostname));
  // }, []);

  // useEffect(() => {
  //   if (pageData?.notFoundPage) {
  //     setContent(pageData.notFoundPage as NotFoundPageQuery['notFoundPage']);
  //   }
  //   if (pageData?.global) {
  //     setGlobal(pageData.global as GlobalFields['global']);
  //   }
  //   if (pageData?.footer) {
  //     setFooter(pageData.footer as GlobalFields['footer']);
  //   }
  //   if (pageData?.allMenuTrees) {
  //     setAllMenuTrees(pageData.allMenuTrees as GlobalFields['allMenuTrees']);
  //   }
  // }, [pageData]);

  // useEffect(() => {
  //   if (pageError) {
  //     setContent(defaultContent);
  //   }
  // }, [pageError]);

  const productsLoading = false;

  if (!content) {
    return null;
  }

  return (
    <>
      <ContentHero
        title={content?.title}
        description={content?.description}
        type="NotFoundView"
      />

      {!!productsLoading && <Loader />}
      {!productsLoading && (
        <Section>
          <Grid
            columns={12}
            columnGap={[
              { columnGap: 15 },
              { breakpoint: 'desktop', columnGap: 30 },
            ]}
          >
            <GridChild
              columnSpan={[
                { columns: 12 },
                { breakpoint: 'desktop', columns: 12 },
              ]}
            >
              <Grid
                columnGap={[
                  { columnGap: 15 },
                  { breakpoint: 'desktop', columnGap: 30 },
                ]}
                columns={[
                  { columns: 1 },
                  { breakpoint: 'mobileMax', columns: 2 },
                  { breakpoint: 'desktop', columns: 3 },
                ]}
              >
                <Products
                  // products={productsData?.items}
                  products={[]}
                  rrpLabel={global.rrpLabel}
                  rrpIncludingVatLabel={global.rrpIncludingVatLabel}
                />
              </Grid>
            </GridChild>
          </Grid>
        </Section>
      )}
    </>
  );
};

export default Custom404;
